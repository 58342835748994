// ThreeBackground.js
import React, {
  useRef,
  useEffect,
  useMemo,
  useState,
  useCallback,
} from 'react';
import { Canvas, useFrame, useThree } from '@react-three/fiber';
import { OrbitControls, Stars } from '@react-three/drei';
import * as THREE from 'three';

function ExpandingNodes() {
  const nodesMeshRef = useRef();       // InstancedMesh for spheres
  const linesRef = useRef();           // lineSegments for lines
  const networkGroupRef = useRef();    // group for scaling/rotation

  // Device tilt state
  const [orientation, setOrientation] = useState({ alpha: 0, beta: 0, gamma: 0 });

  // The line offset so lines don't penetrate the spheres
  const nodeRadius = 0.12;     // Adjust so lines stop at sphere edges
  const SPHERE_RADIUS = 0.1;   // Sphere geometry radius

  // For less dense scene
  const { size, mouse, camera } = useThree();
  // fewer nodes on each device
  const numNodes = useMemo(() => (size.width < 768 ? 40 : 60), [size.width]);

  // A “lighter navy” color
  const NAVY = '#3f7fff';

  // “aha” flash intervals
  const FLASH_INTERVAL = 15000; // pick new node every 15s
  const FLASH_DURATION = 3000;  // stays navy for 3s

  // Indices for lines
  const { positions, lineIndices } = useMemo(() => {
    const positions = new Float32Array(numNodes * 3);
    const lines = [];
    for (let i = 0; i < numNodes; i++) {
      // Distribute nodes
      const x = (Math.random() - 0.5) * 25;
      const y = (Math.random() - 0.5) * 25;
      const z = (Math.random() - 0.5) * 25;
      positions.set([x, y, z], i * 3);

      // Each node => 1 connection
      const connections = 1;
      for (let j = 0; j < connections; j++) {
        const targetIndex = Math.floor(Math.random() * numNodes);
        if (targetIndex !== i) {
          lines.push(i, targetIndex);
        }
      }
    }
    return { positions, lineIndices: new Uint16Array(lines) };
  }, [numNodes]);

  // Refs for data
  const nodesRef = useRef(positions);
  const lineIndicesRef = useRef(lineIndices);

  // Basic states
  const [hovered, setHovered] = useState(null);
  const [flashNode, setFlashNode] = useState(null);
  const [flashStartTime, setFlashStartTime] = useState(0);

  // Scale from 0.15 -> 0.45
  const [scale, setScale] = useState(0.15);
  const startTimeRef = useRef(null);

  // Raycaster
  const raycaster = useMemo(() => new THREE.Raycaster(), []);
  const mouseVector = useMemo(() => new THREE.Vector2(), []);

  // Listen for device orientation to tilt the background
  useEffect(() => {
    function handleOrientation(event) {
      setOrientation({
        alpha: event.alpha  || 0,
        beta:  event.beta   || 0,
        gamma: event.gamma  || 0,
      });
    }
    window.addEventListener('deviceorientation', handleOrientation, true);
    return () => {
      window.removeEventListener('deviceorientation', handleOrientation, true);
    };
  }, []);

  // Lines regeneration
  const regenerateConnections = useCallback(() => {
    const newLines = [];
    for (let i = 0; i < numNodes; i++) {
      const connections = 1; // fewer lines
      for (let j = 0; j < connections; j++) {
        const targetIndex = Math.floor(Math.random() * numNodes);
        if (targetIndex !== i) {
          newLines.push(i, targetIndex);
        }
      }
    }
    const newLineIndices = new Uint16Array(newLines);
    lineIndicesRef.current = newLineIndices;

    if (linesRef.current) {
      linesRef.current.geometry.setIndex(
        new THREE.BufferAttribute(lineIndicesRef.current, 1)
      );
      const lp = linesRef.current.geometry.attributes.position.array;
      const lc = linesRef.current.geometry.attributes.color.array;
      for (let i = 0; i < lp.length; i++) lp[i] = 0;
      for (let i = 0; i < lc.length; i++) lc[i] = 1;
      linesRef.current.geometry.attributes.position.needsUpdate = true;
      linesRef.current.geometry.attributes.color.needsUpdate = true;
      linesRef.current.geometry.index.needsUpdate = true;
    }
  }, [numNodes]);

  // Instanced mesh geometry
  const sphereGeometry = useMemo(
    () => new THREE.SphereGeometry(SPHERE_RADIUS, 8, 8),
    []
  );
  const dummyObj = useMemo(() => new THREE.Object3D(), []);

  // Setup lines initially
  useEffect(() => {
    if (linesRef.current) {
      const linePositions = new Float32Array(lineIndicesRef.current.length * 3);
      const lineColors = new Float32Array(lineIndicesRef.current.length * 3);
      linesRef.current.geometry.setAttribute(
        'position',
        new THREE.BufferAttribute(linePositions, 3)
      );
      linesRef.current.geometry.setAttribute(
        'color',
        new THREE.BufferAttribute(lineColors, 3)
      );
      linesRef.current.geometry.setIndex(
        new THREE.BufferAttribute(lineIndicesRef.current, 1)
      );
      linesRef.current.geometry.computeBoundingSphere();

      for (let i = 0; i < lineColors.length; i++) {
        lineColors[i] = 1;
      }
      linesRef.current.geometry.attributes.color.needsUpdate = true;
    }

    // Regenerate lines every 12s
    const intervalId = setInterval(() => {
      regenerateConnections();
    }, 12000);

    return () => clearInterval(intervalId);
  }, [regenerateConnections]);

  // Flash a random node navy every 15s
  useEffect(() => {
    function pickFlashNode() {
      const randomIdx = Math.floor(Math.random() * numNodes);
      setFlashNode(randomIdx);
      setFlashStartTime(performance.now());
    }
    pickFlashNode();
    const flashTimer = setInterval(pickFlashNode, FLASH_INTERVAL);
    return () => clearInterval(flashTimer);
  }, [numNodes]);

  // Animate
  useFrame((state) => {
    const clock = state.clock;
    if (startTimeRef.current === null) {
      startTimeRef.current = clock.elapsedTime;
    }
    const timeElapsed = clock.elapsedTime - startTimeRef.current;

    // 1) Scale up from 0.15 to 0.45 over 10s
    if (timeElapsed < 10) {
      setScale(THREE.MathUtils.lerp(0.15, 0.45, timeElapsed / 10));
    } else {
      setScale(0.45);
    }
    if (networkGroupRef.current) {
      networkGroupRef.current.scale.set(scale, scale, scale);
    }

    // 2) Tilt from device orientation
    const { alpha, beta, gamma } = orientation;
    // Subtle factor
    const tiltX = beta  * 0.01; // tilt around X axis
    const tiltY = gamma * 0.01; // tilt around Y axis

    // If hovered, rotate a little extra
    let newRotY = tiltY;
    if (hovered !== null) {
      newRotY += 0.01;
    }
    if (networkGroupRef.current) {
      networkGroupRef.current.rotation.x = tiltX;
      networkGroupRef.current.rotation.y = newRotY;
    }

    // 3) Subtle node oscillation
    const posArray = nodesRef.current;
    for (let i = 0; i < numNodes; i++) {
      const idx = i * 3;
      posArray[idx]     += Math.sin(clock.elapsedTime * 0.4 + i) * 0.04;
      posArray[idx + 1] += Math.cos(clock.elapsedTime * 0.4 + i) * 0.04;
      posArray[idx + 2] += Math.sin(clock.elapsedTime * 0.4 + i) * 0.04;
    }

    // 4) Update spheres
    const now = performance.now();
    for (let i = 0; i < numNodes; i++) {
      const idx = i * 3;
      const x = posArray[idx];
      const y = posArray[idx + 1];
      const z = posArray[idx + 2];

      dummyObj.position.set(x, y, z);
      dummyObj.updateMatrix();
      nodesMeshRef.current.setMatrixAt(i, dummyObj.matrix);

      // default = white
      const color = new THREE.Color('hotpink');

      // if hovered => green
      if (i === hovered) {
        color.set('green');
      } 
      // flash node => only if within the 3s window
      else if (i === flashNode && (now - flashStartTime < FLASH_DURATION)) {
        color.set(NAVY);
      }

      nodesMeshRef.current.setColorAt(i, color);
    }
    nodesMeshRef.current.instanceMatrix.needsUpdate = true;
    nodesMeshRef.current.instanceColor.needsUpdate = true;

    // 5) Lines
    const linePositions = linesRef.current.geometry.attributes.position.array;
    const lineColors = linesRef.current.geometry.attributes.color.array;

    for (let i = 0; i < lineIndicesRef.current.length; i += 2) {
      const startNode = lineIndicesRef.current[i];
      const endNode = lineIndicesRef.current[i + 1];
      const segOffset = (i / 2) * 6;

      const startIdx = startNode * 3;
      const endIdx = endNode * 3;
      const startPos = new THREE.Vector3(
        posArray[startIdx],
        posArray[startIdx + 1],
        posArray[startIdx + 2]
      );
      const endPos = new THREE.Vector3(
        posArray[endIdx],
        posArray[endIdx + 1],
        posArray[endIdx + 2]
      );

      // wave effect
      const midX = (startPos.x + endPos.x) / 2;
      const midY = (startPos.y + endPos.y) / 2;
      const wave = Math.sin(clock.elapsedTime * 2 + i) * 0.1;
      startPos.x += wave * (startPos.y - midY) * 0.1;
      startPos.y += wave * (midX - startPos.x) * 0.1;
      endPos.x -= wave * (endPos.y - midY) * 0.1;
      endPos.y -= wave * (midX - endPos.x) * 0.1;

      // offset for sphere radius
      const direction = endPos.clone().sub(startPos).normalize();
      const offsetStart = startPos.clone().add(direction.clone().multiplyScalar(nodeRadius));
      const offsetEnd = endPos.clone().sub(direction.clone().multiplyScalar(nodeRadius));

      linePositions[segOffset    ] = offsetStart.x;
      linePositions[segOffset + 1] = offsetStart.y;
      linePositions[segOffset + 2] = offsetStart.z;
      linePositions[segOffset + 3] = offsetEnd.x;
      linePositions[segOffset + 4] = offsetEnd.y;
      linePositions[segOffset + 5] = offsetEnd.z;

      // if hovered => red
      if (hovered !== null && (startNode === hovered || endNode === hovered)) {
        lineColors[segOffset    ] = 1; // R
        lineColors[segOffset + 1] = 0; // G
        lineColors[segOffset + 2] = 0; // B
        lineColors[segOffset + 3] = 1;
        lineColors[segOffset + 4] = 0;
        lineColors[segOffset + 5] = 0;
      } else {
        // white
        lineColors[segOffset    ] = 1;
        lineColors[segOffset + 1] = 1;
        lineColors[segOffset + 2] = 1;
        lineColors[segOffset + 3] = 1;
        lineColors[segOffset + 4] = 1;
        lineColors[segOffset + 5] = 1;
      }
    }
    linesRef.current.geometry.attributes.position.needsUpdate = true;
    linesRef.current.geometry.attributes.color.needsUpdate = true;

    // 6) Camera drift
    camera.position.x += (mouse.x * 15 - camera.position.x) * 0.1;
    camera.position.y += (mouse.y * 15 - camera.position.y) * 0.1;
    camera.lookAt(0, 0, 0);
  });

  // pointermove => picking handled in group
  return (
    <group
      ref={networkGroupRef}
      onPointerMove={(e) => {
        mouseVector.x = (e.clientX / size.width) * 2 - 1;
        mouseVector.y = -(e.clientY / size.height) * 2 + 1;
        raycaster.setFromCamera(mouseVector, camera);
        if (nodesMeshRef.current) {
          const intersects = raycaster.intersectObject(nodesMeshRef.current);
          if (intersects.length > 0) {
            setHovered(intersects[0].instanceId);
          } else {
            setHovered(null);
          }
        }
      }}
      onPointerOut={() => setHovered(null)}
    >
      <instancedMesh
        ref={nodesMeshRef}
        args={[sphereGeometry, undefined, numNodes]}
        raycast={THREE.Mesh.raycast}
      >
        <meshBasicMaterial vertexColors toneMapped={false} />
      </instancedMesh>

      <lineSegments ref={linesRef} frustumCulled={false}>
        <bufferGeometry />
        <lineBasicMaterial vertexColors linewidth={200} opacity={0.5} transparent />
      </lineSegments>
    </group>
  );
}

function ThreeBackground() {
  return (
    <Canvas
      camera={{ position: [0, 0, 50], fov: 25 }}
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 0,
      }}
    >
      {/* Starfield behind everything */}
      <Stars
        radius={150}
        depth={10}
        count={8000}
        factor={5}
        saturation={0}
        fade={false}
        speed={2}
      />
      <ambientLight intensity={0.5} />
      <OrbitControls
        enableZoom
        enablePan
        enableRotate
        minDistance={10}
        maxDistance={100}
      />
      <ExpandingNodes />
    </Canvas>
  );
}

export default ThreeBackground;
