import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import MainPage from './pages/MainPage';
import Boardroom from './pages/Boardroom';
import NBAASW25 from './pages/NBAASW25'; // Added new page
import ProtectedRoute from './components/ProtectedRoute';
import ErrorBoundary from './ErrorBoundary';

const RoutesWrapper = () => (
  <Routes>
    <Route path="/" element={<LandingPage />} />
    <Route path="/main" element={<MainPage />} />
    <Route path="/nba-asw-25" element={<NBAASW25 />} />

    {/* Protected Routes */}
    <Route
      path="/boardroom"
      element={
        <ProtectedRoute>
          <Boardroom />
        </ProtectedRoute>
      }
    />

    {/* 404 Redirect */}
    <Route path="*" element={<Navigate to="/" replace />} />
  </Routes>
);

function App() {
  return (
    <ErrorBoundary>
      <Router>
        <RoutesWrapper />
      </Router>
    </ErrorBoundary>
  );
}

export default App;
