// src/pages/MainPage.js
import React, { useEffect, useRef, useState } from 'react';
// Import the NET effect from Vanta
import NET from 'vanta/dist/vanta.net.min';
import * as THREE from 'three';
import './MainPage.css';
import Typewriter from '../Typewriter';

import logo from '../assets/1ofnonelogo.PNG'; // Ensure this path is correct

// Importing icons from react-icons
import { FaEnvelope, FaShoppingCart, FaNewspaper, FaFacebook, FaArrowLeft, FaArrowRight } from 'react-icons/fa';

function MainPage() {
  // Update state to handle NET effect instead of HALO
  const [vantaEffect, setVantaEffect] = useState(null);
  const mainRef = useRef(null);

  // Refs for sections
  const consultancyRef = useRef(null);
  const linesOfBusinessRef = useRef(null);
  const contactRef = useRef(null);

  // Optional: Define your Vanta parameters here or fetch them from a function
  const getVantaParams = () => ({
    points: 15.0,      // Example value; adjust as needed
    spacing: 20.0,     // Example value; adjust as needed
  });

  useEffect(() => {
    // Initialize Vanta NET effect
    const initializeVanta = () => {
      if (!vantaEffect) {
        const params = getVantaParams(); // Get custom parameters
        const effect = NET({
          el: mainRef.current,
          THREE: THREE,
          mouseControls: true,
          touchControls: true,
          gyroControls: false,
          minHeight: 200.0,
          minWidth: 200.0,
          scale: 1.0,
          scaleMobile: 1.0,
          color: 0xffffff, // White points and lines
          backgroundColor: 0x000000, // Black background
          points: params.points, // Custom points
          spacing: params.spacing, // Custom spacing
          maxDistance: 30.0, // Slightly increased for more connections
        });
        setVantaEffect(effect);
      }
    };

    initializeVanta();

    // Cleanup on component unmount
    return () => {
      if (vantaEffect) vantaEffect.destroy();
    };
  }, [vantaEffect]); // Depend on vantaEffect to prevent re-initialization

  return (
    <div ref={mainRef} className="mainContainer">
      {/* Navbar */}
      
      {/* Consultancy Section */}
      <section ref={consultancyRef} className="section consultancySection">
        <div className="contentInner">
          {/* Big Logo */}
          <img src={logo} alt="S1-O.N. Logo" className="heroLogo" />

          {/* Mission Statement */}
          <h1 className="mainHeading">
            <strong className="brandName">S1-O.N.</strong> is a consulting agency founded
            to add value to eclectic thinkers and entrepreneurs through{' '}
            <span className="typewriterContainer">
              <Typewriter
                phrases={['Business Development', 'Creative Direction', 'Strategy Consulting']}
                typingSpeed={120}
                pauseTime={1000}
              />
            </span>
            .
          </h1>

          {/* Action Buttons Section */}
          {/* You can add your action buttons here */}
          <div className="actionButtons">
            <button className="actionButton" onClick={() => linesOfBusinessRef.current.scrollIntoView({ behavior: 'smooth' })}>
              Services
            </button>
            <button className="actionButton" onClick={() => contactRef.current.scrollIntoView({ behavior: 'smooth' })}>
              Contact Us
            </button>
          </div>
          {/* End of Action Buttons Section */}
        </div>
      </section>

      {/* Lines of Business Section */}
      <section ref={linesOfBusinessRef} className="section linesOfBusiness">
        <div className="contentInner">
          <h2>Services</h2>

          {/* Business Cards */}
          <div className="businessCards">
            <div className="businessCard">
              <h3>Business Development</h3>
              <hr className="cardDivider" />
              <p>Getting your start-up company off the ground</p>
            </div>

            <div className="businessCard">
              <h3>Creative Direction</h3>
              <hr className="cardDivider" />
              <p>Input from experienced tastemakers and creative entrepreneurs</p>
            </div>

            <div className="businessCard">
              <h3>Strategic Consulting</h3>
              <hr className="cardDivider" />
              <p>
                Input and assistance in the development and execution of specific ideas 
                or events. Optimization of processes for long-term success and 
                scalability.
              </p>
            </div>
          </div>
          {/* End of Business Cards */}

          {/* Navigation Buttons */}
          <div className="navigationButtons">
            <button
              className="navButton prevButton"
              onClick={() => consultancyRef.current.scrollIntoView({ behavior: 'smooth' })}
              aria-label="Navigate to Previous Section"
            >
              <FaArrowLeft className="navButtonIcon" /> Previous
            </button>
            <button
              className="navButton nextButton"
              onClick={() => contactRef.current.scrollIntoView({ behavior: 'smooth' })}
              aria-label="Navigate to Next Section"
            >
              Next <FaArrowRight className="navButtonIcon" />
            </button>
          </div>
        </div>
      </section>

      {/* Contact Section */}
      <section ref={contactRef} id="contactSection" className="section contactSection">
        <div className="contentInner">
          <h2>Contact Us</h2>
          
          <form>
            <label>
              Name:<br />
              <input type="text" name="name" placeholder="Your Name" required />
            </label>
            <label>
              Email:<br />
              <input type="email" name="email" placeholder="Your Email" required />
            </label>
            <label>
              Message:<br />
              <textarea name="message" rows="4" placeholder="Your Message" required></textarea>
            </label>
            <button type="submit">Send</button>
          </form>

          {/* Navigation Buttons */}
          <div className="navigationButtons">
            <button
              className="navButton prevButton"
              onClick={() => linesOfBusinessRef.current.scrollIntoView({ behavior: 'smooth' })}
              aria-label="Navigate to Previous Section"
            >
              <FaArrowLeft className="navButtonIcon" /> Previous
            </button>
            {/* Optionally, add another button or functionality */}
          </div>
        </div>
      </section>
    </div>
  );
}

export default MainPage;
