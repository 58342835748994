// src/pages/Boardroom.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; 
import './Boardroom.css';
import oneOfNoneLogo from '../assets/1ofnonelogo.PNG';

/**
 * ImageWithFallback Component
 * Attempts to load an image. If it fails, displays the alt text.
 */
function ImageWithFallback({ src, alt, className }) {
  const [hasError, setHasError] = useState(false);

  const handleError = () => {
    setHasError(true);
  };

  return !hasError ? (
    <img
      src={src}
      alt={alt}
      className={className}
      onError={handleError}
      loading="lazy" // Improves performance by lazy loading images
    />
  ) : (
    <div
      className={className}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#444',
        color: '#fff',
        fontWeight: 'bold',
        textAlign: 'center',
      }}
    >
      {alt}
    </div>
  );
}

// Importing images from assets
const images = [
  require('../assets/S1ONboardroom12.jpg'),
  require('../assets/S1ONboardroom10.jpg'),
  require('../assets/S1ONboardroom5.jpg'),
  require('../assets/S1ONboardroom6.jpg'),
  require('../assets/S1ONboardroom7.jpg'),
  require('../assets/S1ONboardroom4.jpg'),
  require('../assets/S1ONboardroom11.jpg'),
  require('../assets/S1ONboardroom8.jpg'),
  require('../assets/S1ONboardroom14.jpg'),
  require('../assets/S1ONboardroom13.jpg'),
  require('../assets/S1ONboardroom9.jpg'),
  require('../assets/S1ONboardroom3.jpg'),
  require('../assets/S1ONboardroom1.jpg'),
  require('../assets/S1ONboardroom2.jpg'),
];

const pieces = images.map((image, index) => ({
  id: index + 1,
  alt: `Piece ${index + 1}`,
  images: [image],
}));

function Boardroom() {
  const [isOpen, setIsOpen] = useState(false);
  const [currentPiece, setCurrentPiece] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  // Track touch events for swiping
  const [startX, setStartX] = useState(null);

  const openModal = (piece) => {
    setCurrentPiece(piece);
    setCurrentIndex(0);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setCurrentPiece(null);
    setCurrentIndex(0);
  };

  const goToNext = () => {
    if (!currentPiece) return;
    setCurrentIndex((prev) => (prev + 1) % currentPiece.images.length);
  };

  const goToPrev = () => {
    if (!currentPiece) return;
    setCurrentIndex((prev) => (prev - 1 + currentPiece.images.length) % currentPiece.images.length);
  };

  // Touch event handlers for mobile swipe
  const handleTouchStart = (e) => {
    setStartX(e.touches[0].clientX);
  };

  const handleTouchEnd = (e) => {
    if (startX === null) return;
    const endX = e.changedTouches[0].clientX;
    const deltaX = endX - startX;

    // Threshold for swipe
    if (deltaX > 50) {
      goToPrev(); // Swipe right => previous
    } else if (deltaX < -50) {
      goToNext(); // Swipe left => next
    }
    setStartX(null);
  };

  // Close modal on pressing 'Esc' key
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape' && isOpen) {
        closeModal();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [isOpen]);

  return (
    <div className="boardroom">
      <header>
        {/* Logo now links back to '/' */}
        <Link to="/">
          <img src={oneOfNoneLogo} alt="Syndicate Logo" className="logo" />
        </Link>
        <h2>S1-O.N. x Boardroom</h2>
      </header>

      {/* IG Grid */}
      <div className="ig-grid">
        {pieces.map((piece) => (
          <div
            key={piece.id}
            className="ig-grid-item"
            onClick={() => openModal(piece)}
          >
            {/* Use the fallback image component for the first image */}
            <ImageWithFallback
              src={piece.images[0]}
              alt={piece.alt}
              className="ig-image"
            />
          </div>
        ))}
      </div>

      {/* Modal Carousel */}
     {/* Modal Focused on the Image */}
{isOpen && currentPiece && (
  <div className="modal" onClick={closeModal}>
    <div
      className="modal-content focused"
      onClick={(e) => e.stopPropagation()}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    >
      <ImageWithFallback
        src={currentPiece.images[currentIndex]}
        alt={currentPiece.alt}
        className="modal-image"
      />
    </div>
  </div>
)}


      <footer>© 2025 S1-O.N. All rights reserved.</footer>
    </div>
  );
}

export default Boardroom;
