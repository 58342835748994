import React, { useEffect, useState, useRef } from 'react';
import './LandingPage.css';
import logo from '../assets/1ofnonelogo.PNG';
import logoGlow from '../assets/LogoGlow.png';
import ThreeBackground from '../components/ThreeBackground';
import Typewriter from '../Typewriter';
import Services from '../components/Services';
import Contact from '../components/Contact';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import { FaBriefcase, FaSignInAlt, FaEnvelope } from 'react-icons/fa';

Modal.setAppElement('#root');

function LandingPage() {
  const heroRef = useRef(null);      // NEW: Ref for the top "hero/login" section
  const servicesRef = useRef(null);
  const contactRef = useRef(null);

  const navigate = useNavigate();

  const [showContent, setShowContent] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const glowAnimationDuration = 3000;
    const timeoutId = setTimeout(() => {
      setShowContent(true);
    }, glowAnimationDuration);

    return () => clearTimeout(timeoutId);
  }, []);

  // Smooth scroll function
  const scrollToSection = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Modal logic...
  const openModal = () => {
    setIsModalOpen(true);
    setPassword('');
    setError('');
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setPassword('');
    setError('');
  };

  // In LandingPage.js (excerpt)
  const handlePasswordSubmit = (e) => {
    e.preventDefault();
  
    const correctPassword = 'yourPassword123';
    const boardroomPassword = 'boardroom';
    const nbaEventPassword = 'nbaasw2025'; // NEW password for NBA ASW 25 page
  
    if (password === correctPassword) {
      closeModal();
      navigate('/main');
    } else if (password === boardroomPassword) {
      localStorage.setItem('authorized', 'true');
      closeModal();
      navigate('/boardroom');
    } else if (password === nbaEventPassword) {
      closeModal();
      navigate('/nba-asw-25'); // Navigate to NBA ASW 25 page
    } else {
      setError('Incorrect password. Please try again.');
    }
  };
  

  

  return (
    <div className="landingContainer">
      {/* Background */}
      <div className="threeBackgroundWrapper">
        <ThreeBackground />
      </div>

      {/* Main Content */}
      <div className="contentWrapper">
      <section className="heroSection" ref={heroRef}>
  <div className="logoContainer">
    <img
      src={logo}
      alt="1 of None Logo"
      className="heroLogo active"
      loading="lazy"
    />
    <img
      src={logoGlow}
      alt="Logo Glow"
      className="logoGlow"
      loading="lazy"
    />
  </div>

  <div className="contentFadeInWrapper">
    {showContent && (
      <div className="contentFadeIn">
        <h1 className="mainHeading">
          <strong className="brandName">S1-O.N.</strong> is a management consulting firm founded to add value to eclectic thinkers and entrepreneurs through{' '}
          <span className="typewriterContainer" style={{ color: 'silver' }}>
            <Typewriter
              phrases={['Business Development', 'Creative Direction', 'Strategy Consulting']}
              typingSpeed={120}
              pauseTime={1000}
            />
          </span>
          .
        </h1>
        <div className="actionButtons">
          <button className="actionButton" onClick={() => scrollToSection(servicesRef)}>
            <FaBriefcase style={{ marginRight: '8px' }} />
            Services
          </button>
          <button className="actionButton" onClick={openModal}>
            <FaSignInAlt style={{ marginRight: '8px' }} />
            Login
          </button>
          <button className="actionButton" onClick={() => scrollToSection(contactRef)}>
            <FaEnvelope style={{ marginRight: '8px' }} />
            Contact
          </button>
        </div>
      </div>
    )}
  </div>
</section>
        {/* Services Section (pass the needed props) */}
        <Services
          ref={servicesRef}
          scrollToSection={scrollToSection}
          contactRef={contactRef}
          loginRef={heroRef}   // The hero is basically "login" section
        />

        {/* Contact Section (pass the needed props) */}
        <Contact
          ref={contactRef}
          scrollToSection={scrollToSection}
          servicesRef={servicesRef}
          loginRef={heroRef}
        />
      </div>

      {/* Login Modal */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Login Modal"
        className="Modal"
        overlayClassName="Overlay"
      >
        <div className="modalHeader">
          <img src={logo} alt="1 of None Logo" className="modalLogo" />
          <h2>Enter Password</h2>
        </div>
        <form onSubmit={handlePasswordSubmit}>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            required
          />
          <button type="submit">Submit</button>
        </form>
        {error && <p className="error">{error}</p>}
        <button onClick={closeModal} className="closeButton">
          Close
        </button>
      </Modal>
    </div>
  );
}

export default LandingPage;
