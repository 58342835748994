import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Masonry from 'react-masonry-css';
import { FaDownload, FaShareAlt } from 'react-icons/fa';
import oneOfNoneLogo from '../assets/1ofnonelogo.PNG';
import './NBAASW25.css';
import nbaVideo from '../assets/nbaasw2025.mov';

// Dynamically generate film photos (from public folder)
const filmPhotos = Array.from({ length: 29 }, (_, i) => 
  `/NBA_ASW_2025_FILM/S1onFilm${i + 1}.png`
);

// Dynamically generate digital photos (from public folder)
const digitalPhotos = Array.from({ length: 159 }, (_, i) => 
  `/NBA_ASW_2025_DIGITALS/S1onDigital${i + 1}.jpg`
);

/**
 * Component for each photo in the Masonry grid that shows its own loading spinner.
 */
function MasonryImage({ photo, index, openLightbox }) {
  const [loaded, setLoaded] = useState(false);

  return (
    <div
      className="masonry-item-container"
      onClick={() => openLightbox(photo)}
    >
      <img
        src={photo}
        alt={`Photo ${index + 1}`}
        loading="lazy"
        onLoad={() => setLoaded(true)}
        style={{ opacity: loaded ? 1 : 0 }}
      />
      {!loaded && (
        <div className="photo-loading-overlay">
          <div className="spinner"></div>
        </div>
      )}
    </div>
  );
}

function NBAASW25() {
  const navigate = useNavigate();
  const [showGallery, setShowGallery] = useState(false);
  const [activeTab, setActiveTab] = useState('digital'); // default tab
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  // When the gallery is first shown or the tab changes, we don't need a global loading overlay
  // because each photo handles its own loading state.

  const handleViewContent = () => {
    setShowGallery(true);
  };

  const openLightbox = (photo) => {
    setSelectedImage(photo);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
    setSelectedImage(null);
  };

  const handleDownload = (photo) => {
    const link = document.createElement('a');
    link.href = photo;
    link.download = photo.split('/').pop();
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleShare = async (photo) => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'NBA All-Star Weekend 2025',
          text: 'Check out this image from the NBA event!',
          url: photo,
        });
      } catch (error) {
        console.error('Error sharing', error);
      }
    } else {
      try {
        await navigator.clipboard.writeText(photo);
        alert('Image URL copied to clipboard!');
      } catch (error) {
        alert('Sharing not supported, and failed to copy URL.');
      }
    }
  };

  // Breakpoint configuration for Masonry
  const breakpointColumnsObj = {
    default: 4,
    1100: 3,
    700: 2,
    500: 1,
  };

  return (
    <div className="nbaaswContainer">
      {/* Header with clickable logo */}
      <header className="header">
        <img
          src={oneOfNoneLogo}
          alt="1 of None Logo"
          className="logo"
          onClick={() => navigate('/')}
        />
        <h1>NBA All-Star Weekend 2025</h1>
      </header>

      {!showGallery ? (
        <>
          <div className="videoWrapper">
          <video
              className="nbaVideo"
              controls
              autoPlay
              loop
              muted
              playsInline
              webkit-playsinline
            >
              <source src={nbaVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <button className="viewContentButton" onClick={handleViewContent}>
            View Content
          </button>
        </>
      ) : (
        <div className="gallerySection">
          {/* Tab Navigation */}
          <div className="tabContainer">
            <button
              className={`tabButton ${activeTab === 'digital' ? 'active' : ''}`}
              onClick={() => setActiveTab('digital')}
            >
              Digital Photos
            </button>
            <button
              className={`tabButton ${activeTab === 'video' ? 'active' : ''}`}
              onClick={() => setActiveTab('video')}
            >
              Event Video
            </button>
            <button
              className={`tabButton ${activeTab === 'film' ? 'active' : ''}`}
              onClick={() => setActiveTab('film')}
            >
              Film Photos
            </button>
          </div>

          {/* Tab Content */}
          <div className="tabContent">
            {activeTab === 'digital' && (
              <Masonry
                breakpointCols={breakpointColumnsObj}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column"
              >
                {digitalPhotos.map((photo, index) => (
                  <MasonryImage
                    key={index}
                    photo={photo}
                    index={index}
                    openLightbox={openLightbox}
                  />
                ))}
              </Masonry>
            )}

            {activeTab === 'video' && (
              <div className="videoWrapper">
                

              </div>
            )}

            {activeTab === 'film' && (
              <Masonry
                breakpointCols={breakpointColumnsObj}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column"
              >
                {filmPhotos.map((photo, index) => (
                  <MasonryImage
                    key={index}
                    photo={photo}
                    index={index}
                    openLightbox={openLightbox}
                  />
                ))}
              </Masonry>
            )}
          </div>
        </div>
      )}

      <button className="backButton" onClick={() => navigate('/')}>
        Back to Home
      </button>

      {/* Lightbox Modal */}
{lightboxOpen && (
  <div className="modal" onClick={closeLightbox}>
    <div
      className="modal-content focused"
      onClick={(e) => e.stopPropagation()}
    >
      <img
        src={selectedImage}
        alt="Lightbox"
        className="modal-image"
      />
      <div className="modal-overlay-icons">
        <button
          className="modal-icon-button"
          onClick={(e) => {
            e.stopPropagation();
            handleDownload(selectedImage);
          }}
          title="Download"
        >
          <FaDownload />
        </button>
        <button
          className="modal-icon-button"
          onClick={(e) => {
            e.stopPropagation();
            handleShare(selectedImage);
          }}
          title="Share"
        >
          <FaShareAlt />
        </button>
      </div>
    </div>
  </div>
)}

    </div>
  );
}

export default NBAASW25;
