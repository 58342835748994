// src/components/Contact.js
import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import {
  FaInstagram,
  FaLinkedin,
  FaYoutube,
  FaDiscord,
  FaTwitch,
  FaTwitter,
} from 'react-icons/fa';
import './Contact.css';

const platforms = [
  { label: 'Instagram', icon: <FaInstagram /> },
  { label: 'LinkedIn', icon: <FaLinkedin /> },
  { label: 'YouTube', icon: <FaYoutube /> },
  { label: 'X (Twitter)', icon: <FaTwitter /> },
  { label: 'Discord', icon: <FaDiscord /> },
  { label: 'Twitch', icon: <FaTwitch /> },
];

const Contact = React.forwardRef((props, ref) => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    email: '',
    phone: '',
    firstName: '',
    lastName: '',
    message: '',
    orgName: '',
    website: '',
    socialLinks: [{ platform: '', link: '' }],
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);  // Optional: Loading state

  // Handle input changes for text fields
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: '' })); // Clear errors
  };

  // Handle phone number changes using PhoneInput component
  const handlePhoneChange = (value) => {
    setFormData((prev) => ({ ...prev, phone: value }));
    setErrors((prev) => ({ ...prev, phone: '' })); // Clear phone errors
  };

  // Handle changes in social links
  const handleSocialChange = (index, field, value) => {
    const updatedSocialLinks = [...formData.socialLinks];
    updatedSocialLinks[index][field] = value;
    setFormData((prev) => ({ ...prev, socialLinks: updatedSocialLinks }));
  };

  // Add a new social link row
  const addSocialRow = () => {
    setFormData((prev) => ({
      ...prev,
      socialLinks: [...prev.socialLinks, { platform: '', link: '' }],
    }));
  };

  // Remove a social link row by index
  const removeSocialRow = (index) => {
    const updatedSocialLinks = formData.socialLinks.filter((_, i) => i !== index);
    setFormData((prev) => ({ ...prev, socialLinks: updatedSocialLinks }));
  };

  // Validate form inputs based on the current step
  const validateStep = () => {
    const newErrors = {};
    if (step === 1 && !formData.email) newErrors.email = 'Email is required*';
    if (step === 2) {
      if (!formData.firstName) newErrors.firstName = 'First Name is required*';
      if (!formData.lastName) newErrors.lastName = 'Last Name is required*';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Proceed to the next step if validation passes
  const nextStep = () => {
    if (validateStep()) setStep((prev) => prev + 1);
  };

  // Return to the previous step
  const prevStep = () => setStep((prev) => prev - 1);

  // Handle form submission
  const handleSubmit = async () => {
    if (validateStep()) {
      setIsSubmitting(true);  // Optional: Set loading state
      try {
        const response = await fetch('/api/contact', {  // Post to serverless function
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(formData),
        });

        // Check if the response has content
        const text = await response.text();
        let data;
        try {
          data = JSON.parse(text);
        } catch (err) {
          throw new Error('Invalid JSON response from server.');
        }

        console.log('Server response:', data);

        if (response.ok && data.result === 'Success') {
          // Navigate to the Thank You step
          setStep(5);
          // Optionally, reset form data
          setFormData({
            email: '',
            phone: '',
            firstName: '',
            lastName: '',
            message: '',
            orgName: '',
            website: '',
            socialLinks: [{ platform: '', link: '' }],
          });
        } else {
          const errorMessage = data.error || 'Submission failed. Please try again.';
          alert(`Submission failed: ${errorMessage}`);
        }
      } catch (error) {
        console.error('Error submitting form:', error);
        alert(`Something went wrong: ${error.message}`);
      } finally {
        setIsSubmitting(false);  // Optional: Reset loading state
      }
    }
  };

  // Navigate to Services section
  const handleGoToServices = () => {
    if (props.scrollToSection && props.servicesRef) {
      props.scrollToSection(props.servicesRef);
    }
  };

  // Navigate back to Login section
  const handleGoToLogin = () => {
    if (props.scrollToSection && props.loginRef) {
      props.scrollToSection(props.loginRef);
    }
  };

  return (
    <section className="contactSection" ref={ref}>
      <h2>Contact Us</h2>

      <form className="contactForm" onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
        {/* Step 1: Email and Phone */}
        {step === 1 && (
          <div className="formGroup">
            <label htmlFor="email">Email: *</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="Enter your email"
              required
              disabled={isSubmitting}  // Optional: Disable during submission
            />
            {errors.email && <p className="error">{errors.email}</p>}

            <label htmlFor="phone">Phone (Optional):</label>
            <PhoneInput
              country={'us'}
              value={formData.phone}
              onChange={handlePhoneChange}
              placeholder="Enter phone number"
              inputStyle={{
                width: '100%',
                borderRadius: '12px',
                padding: '12px',
                fontSize: '1rem',
                border: '1px solid rgba(255, 255, 255, 0.2)',
                background: 'rgba(255, 255, 255, 0.08)',
                color: '#ffffff',
              }}
              buttonStyle={{
                borderRadius: '12px 0 0 12px',
              }}
              dropdownStyle={{
                background: '#1a1a1a',
                color: '#ffffff',
              }}
              disabled={isSubmitting}  // Optional: Disable during submission
            />
          </div>
        )}

        {/* Step 2: First Name and Last Name */}
        {step === 2 && (
          <div className="formGroup">
            <label htmlFor="firstName">First Name: *</label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
              placeholder="Enter your first name"
              required
              disabled={isSubmitting}  // Optional: Disable during submission
            />
            {errors.firstName && <p className="error">{errors.firstName}</p>}

            <label htmlFor="lastName">Last Name: *</label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              value={formData.lastName}
              onChange={handleInputChange}
              placeholder="Enter your last name"
              required
              disabled={isSubmitting}  // Optional: Disable during submission
            />
            {errors.lastName && <p className="error">{errors.lastName}</p>}
          </div>
        )}

        {/* Step 3: Message */}
        {step === 3 && (
          <div className="formGroup">
            <label htmlFor="message">Message (Optional):</label>
            <textarea
              id="message"
              name="message"
              rows="4"
              value={formData.message}
              onChange={handleInputChange}
              placeholder="Type your message here..."
              disabled={isSubmitting}  // Optional: Disable during submission
            ></textarea>
          </div>
        )}

        {/* Step 4: Organization, Website, and Social Links */}
        {step === 4 && (
          <div className="formGroup">
            <label htmlFor="orgName">Organization Name (Optional):</label>
            <input
              type="text"
              id="orgName"
              name="orgName"
              value={formData.orgName}
              onChange={handleInputChange}
              placeholder="Enter your organization's name"
              disabled={isSubmitting}  // Optional: Disable during submission
            />
            <label htmlFor="website">Website (Optional):</label>
            <input
              type="text"
              id="website"
              name="website"
              value={formData.website}
              onChange={handleInputChange}
              placeholder="Enter your website URL"
              disabled={isSubmitting}  // Optional: Disable during submission
            />
            <label>Social Links (Optional):</label>
            <div className="scrollableSocialLinks">
              <div className="scrollOverlay" />
              {formData.socialLinks.map((social, index) => (
                <div className="socialRow" key={index}>
                  <select
                    value={social.platform}
                    onChange={(e) => handleSocialChange(index, 'platform', e.target.value)}
                    disabled={isSubmitting}  // Optional: Disable during submission
                  >
                    <option value="">Select Platform</option>
                    {platforms.map((platform) => (
                      <option key={platform.label} value={platform.label}>
                        {platform.label}
                      </option>
                    ))}
                  </select>
                  <input
                    type="url"
                    placeholder="Enter Link"
                    value={social.link}
                    onChange={(e) => handleSocialChange(index, 'link', e.target.value)}
                    disabled={isSubmitting}  // Optional: Disable during submission
                  />
                  <button
                    type="button"
                    onClick={() => removeSocialRow(index)}
                    disabled={formData.socialLinks.length === 1 || isSubmitting}  // Prevent removal during submission
                  >
                    Remove
                  </button>
                </div>
              ))}
            </div>
            <div className="addMoreButtonContainer">
              <button
                type="button"
                className="addMoreButton"
                onClick={addSocialRow}
                disabled={isSubmitting}  // Optional: Disable during submission
              >
                + Add More
              </button>
            </div>
          </div>
        )}

        {/* Step 5: Thank You Message */}
        {step === 5 && (
          <div className="thankYouMessage">
            <h3>Thank You for Your Submission!</h3>
            <p>We'll be in touch shortly.</p>
          </div>
        )}

        {/* Navigation Buttons for form steps */}
        <div className="buttonGroup">
          {/* Show "Previous" button on steps 2-4 */}
          {step > 1 && step < 5 && (
            <button type="button" className="stepButton" onClick={prevStep} disabled={isSubmitting}>
              Previous
            </button>
          )}
          {/* Show "Next" button on steps 1-3 */}
          {step < 4 && (
            <button type="button" className="stepButton" onClick={nextStep} disabled={isSubmitting}>
              Next
            </button>
          )}
          {/* Show "Submit" button on step 4 */}
          {step === 4 && (
            <button type="submit" className="stepButton" disabled={isSubmitting}>
              {isSubmitting ? 'Submitting...' : 'Submit'}
            </button>
          )}
        </div>
      </form>

      {/* Always Visible Navigation Buttons */}
      <div className="alwaysVisibleButtons" style={{ marginTop: '1rem', display: 'flex', gap: '1rem' }}>
        <button className="navButton" onClick={handleGoToServices}>
          Services
        </button>
        <button className="navButton" onClick={handleGoToLogin}>
          Back to Login
        </button>
      </div>
    </section>
  );
});

export default Contact;
