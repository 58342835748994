// src/Typewriter.js
import React, { useState, useEffect } from 'react';
import './Typewriter.css'; // Ensure this CSS file exists and is correctly linked

function Typewriter({ phrases, typingSpeed = 150, pauseTime = 1000 }) {
  const [displayedText, setDisplayedText] = useState('');
  const [phraseIndex, setPhraseIndex] = useState(0);
  const [letterIndex, setLetterIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    if (phraseIndex >= phrases.length) {
      setPhraseIndex(0);
    }

    const currentPhrase = phrases[phraseIndex];
    let timeout;

    if (isDeleting) {
      timeout = setTimeout(() => {
        setDisplayedText(currentPhrase.substring(0, letterIndex - 1));
        setLetterIndex(letterIndex - 1);
        if (letterIndex === 0) {
          setIsDeleting(false);
          setPhraseIndex((phraseIndex + 1) % phrases.length);
        }
      }, typingSpeed / 2);
    } else {
      timeout = setTimeout(() => {
        setDisplayedText(currentPhrase.substring(0, letterIndex + 1));
        setLetterIndex(letterIndex + 1);
        if (letterIndex === currentPhrase.length) {
          setTimeout(() => setIsDeleting(true), pauseTime);
        }
      }, typingSpeed);
    }

    return () => clearTimeout(timeout);
  }, [displayedText, isDeleting, letterIndex, phraseIndex, phrases, typingSpeed, pauseTime]);

  return <span className="typewriter">{displayedText}</span>;
}

export default Typewriter;
