// src/components/ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';

function ProtectedRoute({ children }) {
  // Check if 'authorized' flag is set to 'true' in localStorage
  const isAuthorized = localStorage.getItem('authorized') === 'true';

  // If authorized, render the child component(s); otherwise, redirect to "/"
  return isAuthorized ? children : <Navigate to="/" />;
}

export default ProtectedRoute;
