// src/components/Services.js
import React from 'react';
import './Services.css';

const Services = React.forwardRef((props, ref) => {
  // Button for navigating to Contact
  const handleGoToContact = () => {
    if (props.scrollToSection && props.contactRef) {
      props.scrollToSection(props.contactRef);
    }
  };

  // Button for navigating to Login (hero)
  const handleGoToLogin = () => {
    if (props.scrollToSection && props.loginRef) {
      props.scrollToSection(props.loginRef);
    }
  };

  return (
    <section className="servicesSection" ref={ref}>
      <h2>Services</h2>
      <div className="servicesContainer">
        <div className="serviceCard">
          <h3>Business Development</h3>
          <hr className="cardDivider" />
          <p>Expanding market opportunities, forming partnerships, securing investments, and increasing revenue streams.</p>
        </div>
        <div className="serviceCard">
          <h3>Creative Direction</h3>
          <hr className="cardDivider" />
          <p>Developing compelling brand narratives, visual identity, and product positioning.</p>
        </div>
        <div className="serviceCard">
          <h3>Strategy Consulting</h3>
          <hr className="cardDivider" />
          <p>
          High-level business advisory on positioning, competitive strategy, and operational efficiency.
          </p>
        </div>
      </div>

      {/* Navigation Buttons */}
      <div style={{ marginTop: '0rem', display: 'flex', gap: '1rem' }}>
        <button className="navButton" onClick={handleGoToContact}>
          Contact Us
        </button>
        <button className="navButton" onClick={handleGoToLogin}>
          Back to Login
        </button>
      </div>
    </section>
  );
});

export default Services;
